import React from "react"
import * as classes from "./catalogue.module.css"
import Image from "gatsby-image"

export default function CatalogueWM({ headerTitle, items }) {
  return (
    <section className={classes.products}>
      <h1>{headerTitle}</h1>
      <div className={classes.productGrid}>
        {items.map((item, i) => (
          <div key={i} className={classes.productCard}>
            <Image fluid={item.node.image.childImageSharp.fluid} />
            <p>{item.node.title}</p>
            <span className={classes.kg}>Wash Capacity: {item.node.kg}</span>
          </div>
        ))}
      </div>
    </section>
  )
}
